@import './variables.scss';
@import './mixin.scss';
@import './transition.scss';
@import './element-ui.scss';
@import './sidebar.scss';

body {
  height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}

// label {
//   font-weight: 700;
// }

html {
  height: 100%;
  box-sizing: border-box;
}

#app {
  height: 100%;
    min-width: 1200px;
    overflow-y: auto;
    overflow-x:hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

div:focus {
  outline: none;
}

.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}

// main-container global css  全局样式
.app-container {
  padding: 20px;
  background: #ffffff;
  min-height: 700px;
}


/*dialog*/
.dialog_common{
  .el-input.el-input--small {
    width: 344px;
    height: 30px;
    .el-input__inner{
      line-height: 30px;
      height: 30px;
    }
  }
  .el-textarea{
    height: 100px;
    width: 344px;
    textarea.el-textarea__inner{
      height: 100px;
    }
  }
  .dialog-footer{
    button{
      width: 125px;
    }
  }
  .el-dialog__header{
    background: $base_color_diaBg;
    padding: 11px 20px 11px;
    text-align: left;
    .el-dialog__headerbtn{
      top: 11px;
    }
  }
  .el-dialog--center .el-dialog__body{
    padding: 25px 25px 0px;
  }
  .el-tree{
    width: 344px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    min-height: 100px;
  }
  .el-form-item{
    margin-bottom: 10px;
  }
  .el-form-item__error{
    padding-top: 0px;
  }

  .fix_item{
    margin-top: 15px;
    margin-bottom: 22px;
    .el-form-item__error{
      top: 105%;
    }
  }
  .fixL label{
    line-height: 30px;
  }
}
/*fix*/
.fixRange{
  .el-form-item__content{
    line-height: 44px;
  }
}


/*fix-form-inline*/
.fix-form-inline{
    .fix-inline{
        margin-bottom: 0;
        margin-right: 2.5%;
        .el-form-item__label{
            color: #555555;
            font-size: 16px;
            display: block;
            text-align: left;
            line-height: 32px;
        }
        .el-form-item{
            width: 14.5%;
            margin-right: 2.5%;
            .el-form-item__content{
                width: 100%;
                .el-select{
                    width: 100%;
                }
            }
        }
    }
    .el-select .el-input__inner{
        width: 255px;
        margin-right: 1%;
    }
}
//  #app .openSidebar .sidebar-container.has-logo .el-scrollbar{
//   //max-height: calc(100vh - 45vh);
// }
 .fix-inline-title{
     margin-right: 0;
     .el-form-item__label{
         color: #555555;
         font-size: 16px;
         display: block;
         text-align: left;
         margin-top: 52px;
         line-height: 0;
     }
 }
 .fix_ad{
     width: 110px;
     .el-form-item__content{
         width: 100%;
         .el-select{
             width: 100%;
         }
     }
 }

 /*table*/
.el-table .tableHeader{
    th.is-leaf{
        background: #ffffff;
        color: #606266;
        font-weight: 400;
    }
}
.elTable{
    .el-table__body tr:hover>td{
        background: #ffffff;
    }
}
.elTable{
    .cell{
        padding: 0;
    }
}
.el-table .tableHeaders{
    th.is-leaf,.is-group th{
        background: #85C226;
        color: #ffffff;
        font-weight: 400;
        padding: 0;
        border-bottom: 1px solid #85C226;
        border-right: 1px solid #85C226;
        .cell{
            padding: 0;
        }
    }
}
.el-table thead.is-group .tableHeaders th{
    background: #85C226;
    color: #ffffff;
    font-weight: 400;
    padding: 0;
    border-bottom: 1px solid #85C226;
    border-right: 1px solid #85C226;
    .cell{
        padding: 0;
    }
}
#app .sidebar-container .el-submenu .el-menu-item{
  font-size: 14px!important;
  margin-left: 10px;
}
.el-menu--vertical .el-menu.el-menu--popup .menu-wrapper .el-menu-item:hover, #app .sidebar-container .nest-menu .el-submenu>.el-submenu__title:hover{
  background-color: linear-gradient(90deg, #026e2d 0%, #8bc21f 100%)!important;
}
#app .sidebar-container .el-submenu .router-link-active .el-menu-item,.el-menu--vertical .menu-wrapper .router-link-active .el-menu-item{
  background: linear-gradient(90deg, #026e2d 0%, #8bc21f 100%)!important;
  color: #ffffff!important;
}
/*开关样式修复*/
.el-switch.fix{
    color: #ffffff;
    display: inline-block;
    vertical-align: revert;
    height: 26px;
    line-height: 26px;
    .el-switch__core{
        border-radius: 20px;
        height: 26px;
        width: 60px!important;
        &:after{
            top:4.1px
        }
    }
}
.el-switch.fix{
    &.is-checked{
        .el-switch__core:before{
            content: '开启';
            margin-left:-12px;
        }
    }
    .el-switch__core:before{
        content: '关闭';
        margin-left:10px;
    }
}

































































.elmenu{
  flex: 1;
  overflow-y: scroll;
}
/* 滚动条样式 */
::-webkit-scrollbar{
    width: 0px;
    background-color: cornflowerblue;
}
/* 滚动滑块样式 */
::-webkit-scrollbar-thumb{
    background-color: skyblue;
    height: 8px;
    border-radius: 10px;
}





























































































@import "@/styles/variables.scss";
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
.el-menu-item {
  padding: 0 40px;
  font-size: 18px;
  font-family: Microsoft YaHei;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid $base_color;
  color: $base_color;
}

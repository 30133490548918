
















































































































































































































@import "@/styles/variables.scss";
.navbar {
  /*height: 50px;*/
  /*overflow: hidden;*/
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    /*float: left;*/
    clear: both;
    display: block;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;
    &:focus {
      outline: none;
    }
    // 管理中心
    .manageCenter {
      font-size: 14px;
      color: #606266;
      margin-right: 30px;
      &:hover {
        cursor: pointer;
      }
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
.nav-h {
  box-shadow: 1px 2px 1px 0px rgba(216, 217, 221, 0.26);
  border-bottom: 1px solid rgba(216, 217, 221, 0.26);
  overflow: hidden;
}
.nav-sub {
  font-size: 19.05px;
  color: $base_color;
  line-height: 47px;
  font-family: FZLanTingHei-EB-GBK;
  font-weight: 600;
  float: left;
  .sub {
    span {
      color: $base_color_light;
      font-size: 28px;
      vertical-align: bottom;
    }
  }
}
/*alarm样式兼容*/
.alarm_lock_nav {
  .fix_div {
    border-bottom: 1px solid #d8d9dd;
  }
  .nav-h {
    margin: auto;
    max-width: 1200px;
    box-shadow: none;
    border-bottom: 0;
  }
  .breadcrumb-container {
    margin: auto;
    max-width: 1200px;
  }
}






























































































  @import "@/styles/variables.scss";
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  /*height: 50px;*/
  /*line-height: 50px;*/
  /*background: #2b2f3a;*/
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 120px;
      // height: 123px;
      vertical-align: middle;
      /*margin-right: 12px;*/
      display: block;
      margin: auto;
      margin-top: 16px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: $base_color;
      font-weight: 600;
      line-height: 28px;
      font-size: 18px;
      font-family: FZLanTingHei-DB-GBK,Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
        // margin-top: -10px;
    }
    & .sidebar-p{
      /*display: inline-block;*/
      margin: 0;
      color: $base_color_grey;
      font-weight: 600;
      line-height: 21px;
      font-size: 12px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
      & .sidebar-layout{
          color: #888888;
          font-weight: 600;
          line-height: 22px;
          font-size: 11px;
          font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
          vertical-align: middle;
          border: 1px solid #DCDCDC;
          border-radius: 20px;
          display: inline-block;
          padding: 0 20px;
          margin: 20px 0 50px;
      }
  }

  &.collapse {
    .sidebar-logo {
      /*margin-right: 0px;*/
        width: 33px;
        height: 37px;
        margin: 10px auto;
    }
  }
}

// sidebar
$menuText:#bfcbd9;
$menuActiveText:#409EFF;
$subMenuActiveText:#f4f4f5; //https://github.com/ElemeFE/element/issues/12951

$menuBg:#ffffff;
$menuHover:linear-gradient(90deg,rgba(2,110,45,1) 0%,rgba(139,194,31,1) 100%);;

/*$subMenuBg:#1f2d3d;*/
$subMenuHover:#001528;

$sideBarWidth: 253px;
/*sccs==============*/

//fontColor
$baseFontColor:#555555;

//fontSize
$baseFontSize:16px;
$baseFontSize_sm:12px;

/*base_color*/
$base_color:#016E2D;
$base_color_1:#026E2D;
$base_color_grey:#666666;
$base_color_light:#8BC21F;
$base_color_light_btn:#8AC21F;
$base_color_danger:#f56c6c;
$base_color_diaBg:#EEF3EE;

/*end========*/
// the :export directive is the magic sauce for webpack
// https://www.bluematador.com/blog/how-to-share-variables-between-js-and-sass
:export {
  menuText: $menuText;
  menuActiveText: $menuActiveText;
  subMenuActiveText: $subMenuActiveText;
  menuBg: $menuBg;
  menuHover: $menuHover;
  //subMenuBg: $subMenuBg;
  subMenuHover: $subMenuHover;
  sideBarWidth: $sideBarWidth;
  $base_color:$base_color
}

@import "./variables";
// cover some element-ui styles

.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

.el-upload {
  input[type="file"] {
    display: none !important;
  }
}

.el-upload__input {
  display: none;
}


// to fixed https://github.com/ElemeFE/element/issues/2461
.el-dialog {
  transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
}

// refine element ui upload
.upload-container {
  .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      height: 200px;
    }
  }
}

// dropdown
.el-dropdown-menu {
  a {
    display: block
  }
}
.menu-wrapper .el-menu-item{
  color: $baseFontColor!important;
  font-size: $baseFontSize;
  padding-left: 12%!important;
  height: 6vh;
  min-height: 36px;
  line-height: 6vh;
}
.el-menu--vertical{
  .menu-wrapper .el-menu-item{
    font-size: 14px;
    &:hover{
      background:linear-gradient(90deg,rgba(2,110,45,1) 0%,rgba(139,194,31,1) 100%)!important;
      color: #ffffff!important;
    }
  }
}
.router-link-active .el-menu-item.submenu-title-noDropdown,.router-link-active .el-menu-item.submenu-title-noDropdown:hover{
  background:linear-gradient(90deg,rgba(2,110,45,1) 0%,rgba(139,194,31,1) 100%)!important;
  color: #ffffff!important;
}
.submenu-title-noDropdown:hover{
  background:linear-gradient(90deg,rgba(2,110,45,1) 0%,rgba(139,194,31,1) 100%)!important;
  color: #ffffff!important;
}
.navbar .right-menu .avatar-container .avatar-wrapper{
  //line-height: 60px;
  margin-top: 0!important;
}
.navbar .right-menu .avatar-container .avatar-wrapper .el-icon-caret-bottom{
  top: 20px!important;
}
.el-table thead th,.el-table tbody td{
  //text-align: center;
}
.el-table thead th.is-leaf{
  background: #EEF3EE;
  color: $base_color;
}
.el-table .success-row {
  background: #F8FEEF;
}
.page-g{
  text-align: center;
  margin-top: 20px;
}
.el-pager li.active{
  color: #ffffff;
  background: $base_color;
  border-radius: 50%;
}
.el-pager li{
  min-width: 20.5px;
  height: 20px;
}
.el-button{
  padding: 10px 20px;
}
.el-input__inner{
  height: 36px;
  line-height: 36px;
}
.icon-btn{
  padding: 7px 20px;
  i{
    margin-right: 10px;
    font-size: 20px;
    vertical-align: middle;
  }
}
i.success{
  color: $base_color_light_btn;
  cursor: pointer;
}
i.danger{
  color: $base_color_danger;
  cursor: pointer;
}
i.base1{
  color: $base_color_1;
  cursor: pointer;
}
.el-table__body td i{
  font-size: 20px;
}
.el-button--success.is-active, .el-button--success:active{
  background: $base_color_light_btn;
  border-color: $base_color_light_btn;
}
.el-button--info{
  background: #C9C9C9;
  border-color: #C9C9C9;
}
.msgBoxConfirm.el-button--primary{
  background: $base_color_danger;
  border-color: $base_color_danger;
}
.msgBoxConfirm.el-button--primary:hover{
  background: $base_color_danger;
  border-color: $base_color_danger;
}

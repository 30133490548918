























































  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";


.sidebar-container{
    display: flex;
    flex-direction: column;
  }
  #app .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
      .main-container{
          // margin-left: 0;
          /*max-width: 1200px;*/
          /*margin: auto;*/
          background: #eeeff3;
      }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
